import { Injectable } from '@angular/core';
import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpHeaders,
	HttpInterceptor,
	HttpRequest,
	HttpResponse
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
// import {Router, RouterState} from '@angular/router';
import {Router, NavigationEnd } from '@angular/router';
import {catchError, tap} from 'rxjs/operators';
import {SpinnerService} from './spinner/spinner.service';
import {MessageService} from 'primeng/api';
import {UserIdService} from './user-id.service';
import {RoleBasedAccessProviderService} from '../psw-approval-flow/role-based-access-provider.service';

@Injectable({
	providedIn: 'root'
})
export class HttpRequestInterceptor implements HttpInterceptor{
	/** This method is required by the fact that this class implements HttpInterceptor.
	 * It accepts (intercepts) HttpRequests and adds the headers required by API's for this application.
	 *
	 * @param request the HttpRequest object
	 * @param next the Http handling object
	 * @returns the Observable representing the next HttpEvent
	 */
	constructor(private router: Router, private spinnerService: SpinnerService, public messageService: MessageService,
				private idService: UserIdService) {
	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (request.url.indexOf('storage.googleapis.com/epsw') !== -1){
			this.spinnerService.show();
			return next.handle(request).pipe(
				tap(evt => {
						if (evt instanceof HttpResponse) {
							this.spinnerService.hide();
						}
					}
					, (error1) => {
						this.spinnerService.hide();
					}),
				catchError((error: HttpErrorResponse): Observable<HttpEvent<any>> => {
					return throwError(error);
				})
			);
		}
		const token = sessionStorage.getItem('encodedAccessToken');
		const authTokenOnly = {
			Authorization: 'Bearer ' + token
		};
		const authTokenAndContentType = {
			'Content-Type': 'application/json',
			Authorization: 'Bearer ' + token
		};
		const contenTypeOnly = {
			'Content-Type': 'application/json'
		};
		const emptyHeader = {};

		function isUploadTemplateEndpoint() {
			if (request.url.indexOf('ppapPackage/commodityImage')!== -1) {
				return true;
			} else if (request.url.indexOf('/files') !== -1){
				return true;
			} else if (request.url.indexOf('tier2/excelUpload') !== -1){
				return true;
			} else if (request.url.indexOf('supplierView/familyAnnexExcelUpload') !== -1){
				return true;
			} else {
				return false;
			}
		}

		function buildHeaders(uploadFileHeader, defaultHeader) {
			return isUploadTemplateEndpoint() ? uploadFileHeader : defaultHeader;
		}

		function getHeaders() {
			return buildHeaders(authTokenOnly, authTokenAndContentType);
		}

		request = request.clone({
			headers: new HttpHeaders(getHeaders())
		});
		// //////////////
		// request = request.clone({
		// 	setHeaders: {
		// 		Authorization: 'Bearer ' + sessionStorage.getItem('encodedAccessToken'),
		// 		'Content-Type': 'application/json'
		// 	}
		// });
		// return next.handle(request);
		this.spinnerService.show();
		return next.handle(request).pipe(
			tap(evt => {
					if (evt instanceof HttpResponse) {
						this.spinnerService.hide();
					}
				}
				, (error1) => {
					this.spinnerService.hide();
				}),
			catchError((error: HttpErrorResponse): Observable<HttpEvent<any>> => {
				const serverError = 500;
				const badRequest = 400;
				const notFound = 404;
				const postError = 401;
				const communicationFailure = 0;
				const forbidden = 403;
				const statusCodes = [notFound, communicationFailure];
				/*if (error && error.status === postError) { commented for reaching some scenarios
					sessionStorage.clear();
					this.router.navigate(['/no-access']);
				} else*/
				if (error instanceof HttpErrorResponse) {
					if(!(request.url.includes('tier2/excelUpload')) &&
						!(request.url.includes('supplierView/familyAnnexExcelUpload'))){
						if (error.error instanceof ErrorEvent) {
							console.error('Error Event in angular');
						} else {
							console.log(`error status : ${error.status} ${error.statusText}`);
							switch (error.status) {
								case 400:
									this.messageService.add({
										severity: 'error',
										summary: 'error',
										detail: 'Unable to retrieve/update data.  Please try after sometime. If issue persists please contact application support team.'
									});
									break;
								case forbidden:
									console.log('403 Forbidden URL: '+this.router.routerState.snapshot.url);
									if('/no-access' !== this.router.routerState.snapshot.url) {
										this.router.navigate(['/no-access']);
									}
									error = null;
									break;
								case 401:
									console.log('Router URL in Unauthorized state');
									console.log(this.router.routerState.snapshot.url)
									if('/login' !== this.router.routerState.snapshot.url){
										console.log('Current URl is not Login URL');
										sessionStorage['redirectURL'] = this.router.routerState.snapshot.url;
										sessionStorage.removeItem('tokenExp');
										sessionStorage.removeItem('tokenIssue');
										sessionStorage.removeItem('strAccessToken');
										sessionStorage.removeItem('encodedAccessToken');
										this.idService.setUserID('Not Logged In');
										this.router.navigateByUrl('/login');
										this.messageService.add({
											severity: 'error',
											summary: 'error',
											detail: 'Session Expired. Please Login to proceed further.'
										});
									}
									break;
							}
						}
					}
				} else {
					console.error('Error in Calling Services');
				}
				return throwError(error);
			})
		);
	}

	/**
	 * Return the error message when API fails.
	 * if LDAP API fails, returns different error message contents w.r.t ford user & suppliers.
	 * for other API fails returns the default error message.
	 */
	errorMessageContent(request): string {
		let message: string = 'Unable to retrieve/update data.  ' +
			'Please try after sometime. ' +
			'If issue persists please contact application support team.';
		if (request.url.includes('ldap/lookup')) {
			const cdsid: string = request.url.split('/').pop();
			message = cdsid + ' may have left the Ford Organization. Please update the STA CDSID in Priority Selection.'
			const apsAttributes: any = sessionStorage.getItem('attributes');
			const userRole: string = JSON.parse(apsAttributes).userRole;
			let pswAccessControl: Map<string, any> = new Map<string, any>();
			pswAccessControl = RoleBasedAccessProviderService.getAccessForSupplierRole(userRole);
			if (!pswAccessControl.get('isSupplier')) {
				message = cdsid + ' may left the Ford Organization. Please contact supplier to update the CDSID.'
			}
		}
		return message
	}
	/////////////////

	// /** This method is required by the fact that this class implements HttpInterceptor.
	//  * It accepts (intercepts) HttpRequests and adds the headers required by API's for this application.
	//  *
	//  * @returns the Ob	servable representing the next HttpEvent
	//  * @param router
	//  * @param spinnerService
	//  */
	//
	// constructor(private router: Router) {
	// }
	//
	// intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
	// 	const token = sessionStorage.getItem('encodedAccessToken');
	// 	const authTokenOnly = {
	// 		Authorization: 'Bearer ' + token
	// 	};
	// 	const authTokenAndContentType = {
	// 		'Content-Type': 'application/json',
	// 		Authorization: 'Bearer ' + token
	// 	};
	// 	const contenTypeOnly = {
	// 		'Content-Type': 'application/json'
	// 	};
	// 	const emptyHeader = {};
	//
	// 	function isUploadTemplateEndpoint() {
	// 		return request.url.indexOf('historicalMfgPerf/uploadHstMfgExcel') !== -1;
	// 	}
	//
	// 	function buildHeaders(uploadTemplateHeader, defaultHeader) {
	// 		return isUploadTemplateEndpoint() ? uploadTemplateHeader : defaultHeader;
	// 	}
	//
	// 	function getHeaders() {
	// 		return buildHeaders(authTokenOnly, authTokenAndContentType);
	// 	}
	//
	// 	request = request.clone({
	// 		headers: new HttpHeaders(getHeaders())
	// 	});
	// 	this.spinnerService.show();
	// 	return next.handle(request).pipe(
	// 		tap(evt => {
	// 				if (evt instanceof HttpResponse) {
	// 					this.spinnerService.hide();
	// 				}
	// 			}
	// 			, (error1) => {
	// 				this.spinnerService.hide();
	// 			}),
	// 		/*catchError((err: any) => {
	// 			if (err instanceof HttpErrorResponse) {
	// 				console.log('error');
	// 				sessionStorage.clear();
	// 				this.router.navigate(['/404']);
	// 			}
	// 			return of(err);
	// 		})*/
	//
	// 		catchError((error: HttpErrorResponse): Observable<HttpEvent<Object>> => {
	// 			const serverError = 500;
	// 			const badRequest = 400;
	// 			const notFound = 404;
	// 			const postError = 401;
	// 			const communicationFailure = 0;
	// 			const statusCodes = [notFound, communicationFailure];
	// 			/*if (error && error.status === postError) { commented for reaching some scenarios
	// 				sessionStorage.clear();
	// 				this.router.navigate(['/no-access']);
	// 			} else*/
	// 			if (statusCodes.indexOf(error.status) >= 0) {
	// 				sessionStorage.clear();
	// 				this.router.navigate(['/404']);
	// 			} else {
	// 				console.log('Unhandled HTTP Error caught:');
	// 				console.log(error);
	// 			}
	// 			return throwError(error);
	// 		})
	// 	);
	// }
}
