import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Config} from '../config';
import {Observable} from 'rxjs';
import {AllPPAPPhase} from '../models/allPPAPPhase';
import {map} from 'rxjs/operators';
import {PpapPackageIdentifier} from '../models/ppap-package-identifier';
import {SubSupplierPpapPackage} from '../models/sub-supplier-ppap-package';
import {PswResponse} from '../models/response/PswResponse';

@Injectable({
	providedIn: 'root'
})
export class EpswHomepageService {

	private config = new Config();
	private httpUrl = `${this.config.url}`;
	private httpUrlGetSiteCodes = `${this.config.url}/epsw/getSiteCode`;
	private httpUrlGetNoOfProgram = `${this.config.url}/epsw/noOfProgram`;
	private httpUrlGetNoOfCommodities = `${this.config.url}/epsw/noOfCommodities`;
	private httpUrlGetSupplierName = `${this.config.url}/epsw/getSupplierName`;
	private httpUrlBeyondSixMonthValues = `${this.config.url}/epsw/getBeyondSixMonthValues`;
	private httpUrlPastDueValues = `${this.config.url}/epsw/getPastDueValues`;
	private httpUrlNextSixMonthValues = `${this.config.url}/epsw/getNextSixMonthValues`;
	private httpUrlPartsUntimedValues = `${this.config.url}/epsw/getPartsUntimedValues`;
	private httpUrlGetAllPPAPPhase = `${this.config.url}/epsw/allPPAPPhase`;
	private httpUrlGetBeyondSixPPAPPhase = `${this.config.url}/epsw/beyondSixPPAPPhase`;
	private httpUrlGetPastDuePPAPPhase = `${this.config.url}/epsw/pastDuePPAPPhase`;
	private httpUrlGetNextSixPPAPPhase = `${this.config.url}/epsw/nextSixPPAPPhase`;
	private httpUrlGetPartsUntimedPPAPPhase = `${this.config.url}/epsw/partsUntimedPPAPPhase`;
	private httpUrlGetData = `${this.config.url}/ppapPackageData/getData`;
	private httpUrlPpapElementSection = `${this.config.url}/epsw/ppapElementSection`;
	private httpUrlPpapElementQuestion = `${this.config.url}/epsw/ppapElementQuestion`;
	private httpUrlSubSupplierPpap = `${this.config.url}/epsw/subSupplier/savePackage`;
	private httpUrlSubSupplierLoadedData = `${this.config.url}/epsw/subSupplier/getAllData`;
	private httpUrlSubSupplierDeleteRecord = `${this.config.url}/epsw/subSupplier/deleteRecord`;
	private httpUrlSubSupplierUpdateRecord = `${this.config.url}/epsw/subSupplier/updateRecord`;
	private httpUrlPpapPackageSave = `${this.config.url}/epsw/ppapPackage/savePackage`;
	private httpUrlPpapPackageRetrieve = `${this.config.url}/epsw/ppapPackage/retrievePackage`;
	private httpUrlPpapPackagePartsRetrieve = `${this.config.url}/epsw/ppapPackage/retrievePpapPackageParts`;
	private httpUrlPpapPartsCmmsApprovalStatus = `${this.config.url}/epsw/ppapPackage/retrieveCmmsApprovalStatus`;
	private httpUrlPpapPackageCommodityimageSave = `${this.config.url}/epsw/ppapPackage/commodityImage`;
	private httpUrlPpapPackageRetrieveCommodityimage = `${this.config.url}/epsw/ppapPackage/commodityImage/`;
	private httpUrlGetNoActionRequiredPPAPPhase = `${this.config.url}/epsw/noActionRequiredPPAPPhase`;
	private httpUrlGetProgramCodeValues = `${this.config.url}/epsw/ppapPackage/retrieveProgramCodeValues`;
	private httpUrlGetProgramSiteCodeValuesFromParts = `${this.config.url}/epsw/ppapPackage/retrieveProgramSiteCodeValuesFromParts`;
	private httpUrlPgmBeyondSixMonthValues = `${this.config.url}/epsw/program/getPgmBeyondSixMonthValues`;
	private httpUrlPgmPastDueValues = `${this.config.url}/epsw/program/getPgmPastDueValues`;
	private httpUrlPgmNextSixMonthValues = `${this.config.url}/epsw/program/getPgmNextSixMonthValues`;
	private httpUrlPgmPartsUntimedValues = `${this.config.url}/epsw/program/getPgmPartsUntimedValues`;
	private httpUrlGetPrgmBeyondSixPPAPPhase = `${this.config.url}/epsw/program/prgmBeyondSixPPAPPhase`;
	private httpUrlGetPrgmPastDuePPAPPhase = `${this.config.url}/epsw/program/prgmPastDuePPAPPhase`;
	private httpUrlGetPrgmNextSixPPAPPhase = `${this.config.url}/epsw/program/prgmNextSixPPAPPhase`;
	private httpUrlGetPrgmPartsUntimedPPAPPhase = `${this.config.url}/epsw/program/prgmPartsUntimedPPAPPhase`;
	private httpUrlGetPrgmNoActionRequiredPPAPPhase = `${this.config.url}/epsw/program/prgmNoActionRequiredPPAPPhase`;
	private httpUrlGetProgramData = `${this.config.url}/epsw/program/getProgramData`;
	private httpUrlGetPrgmSupplierName = `${this.config.url}/epsw/program/getPrgmSupplierName`;
	private httpUrlGetProgramSiteCodeValues = `${this.config.url}/epsw/ppapPackage/retrieveProgramSiteCodeValues`;
	private httpUrlPgmSiteBeyondSixMonthValues = `${this.config.url}/epsw/program/getPgmSiteBeyondSixMonthValues`;
	private httpUrlPgmSitePastDueValues = `${this.config.url}/epsw/program/getPgmSitePastDueValues`;
	private httpUrlPgmSiteNextSixMonthValues = `${this.config.url}/epsw/program/getPgmSiteNextSixMonthValues`;
	private httpUrlPgmSitePartsUntimedValues = `${this.config.url}/epsw/program/getPgmSitePartsUntimedValues`;
	private httpUrlGetPrgmSiteBeyondSixPPAPPhase = `${this.config.url}/epsw/program/prgmSiteBeyondSixPPAPPhase`;
	private httpUrlGetPrgmSitePastDuePPAPPhase = `${this.config.url}/epsw/program/prgmSitePastDuePPAPPhase`;
	private httpUrlGetPrgmSiteNextSixPPAPPhase = `${this.config.url}/epsw/program/prgmSiteNextSixPPAPPhase`;
	private httpUrlGetPrgmSitePartsUntimedPPAPPhase = `${this.config.url}/epsw/program/prgmSitePartsUntimedPPAPPhase`;
	private httpUrlGetPrgmSiteNoActionRequiredPPAPPhase = `${this.config.url}/epsw/program/prgmSiteNoActionRequiredPPAPPhase`;
	private httpUrlGetProgramSiteData = `${this.config.url}/epsw/program/getProgramSiteData`;
	private httpUrlGetExcelData = `${this.config.url}/epsw/ppapPackage/getExcelSiteData`;
	private httpUrlGetSiteCodeValuesForProgram = `${this.config.url}/epsw/ppapPackage/retrieveSiteCodeValuesForProgram`;
	private httpUrlGetSiteCodeValuesForProgramCode = `${this.config.url}/epsw/ppapPackage/retrieveSiteCodeValuesForProgramCode`;

	constructor(private http: HttpClient) {
	}

	getSiteCodeValues() {
		console.log('Site Code Service Call Start');
		return this.http.get<any>(this.httpUrlGetSiteCodes);
		console.log('Site Code Service Call End');
	}

	getNoOfProgram(siteCodeList) {
		console.log('No Of Program Service Call Start');
		const params = new HttpParams().set('siteCodeList',siteCodeList);
		return this.http.post<any>(this.httpUrlGetNoOfProgram, siteCodeList);
		console.log('No Of Program Service Call End');
	}

	getNoOfCommodities(siteCodeList){
		console.log('No Of Commodities Service Call Start');
		const params = new HttpParams().set('siteCodeList',siteCodeList);
		return this.http.post<any>(this.httpUrlGetNoOfCommodities, siteCodeList);
		console.log('No Of Commodities Service Call End');
	}

	getBeyondSixMonthValues(siteCodeList, ppapOption, user) {
		console.log('Beyond 6 months Tree Map Values Service Call Start');
		let navUserRole = sessionStorage.getItem('navUserRole');
		console.log(navUserRole);
		const params = new HttpParams().set('ppapOption', ppapOption).set('user', user).set('navUserRole',sessionStorage.getItem('navUserRole').toUpperCase());
		return this.http.post<any>(this.httpUrlBeyondSixMonthValues, siteCodeList,{params});
		console.log('Beyond 6 months Tree Map Values Service Call End');
	}

	getPastDueValues(siteCodeList, ppapOption, user) {
		console.log('Past Due Tree Map Values Service Call Start');
		const params = new HttpParams().set('ppapOption', ppapOption).set('user', user).set('navUserRole',sessionStorage.getItem('navUserRole').toUpperCase());
		return this.http.post<any>(this.httpUrlPastDueValues, siteCodeList,{params});
		console.log('Past Due Tree Map Values Service Call End');

	}

	getNextSixMonthValues(siteCodeList, ppapOption, user) {
		console.log('Next Six Months Tree Map Values Service Call Start');
		const params = new HttpParams().set('ppapOption', ppapOption).set('user', user).set('navUserRole',sessionStorage.getItem('navUserRole').toUpperCase());
		return this.http.post<any>(this.httpUrlNextSixMonthValues, siteCodeList,{params});
		console.log('Past Due Tree Map Values Service Call End');

	}

	getPartsUntimedValues(siteCodeList, ppapOption, user) {
		console.log('VPP Untimed Tree Map Values Service Call Start');
		const params = new HttpParams().set('ppapOption', ppapOption).set('user', user).set('navUserRole',sessionStorage.getItem('navUserRole').toUpperCase());
		return this.http.post<any>(this.httpUrlPartsUntimedValues, siteCodeList,{params});
		console.log('VPP Untimed Tree Map Values Service Call End');

	}

	getAllPPAPPhase(siteCodeList, ppapOption) : Observable<AllPPAPPhase> {
		console.log('All Type Phase Service Call Start');
		return this.http.get<AllPPAPPhase>(this.httpUrlGetAllPPAPPhase + '/' + siteCodeList + '/' + ppapOption)
			.pipe(map((allPPAPPhase: AllPPAPPhase) => {
				return new AllPPAPPhase(allPPAPPhase)
			}));;
		console.log('All Phase Service Call End');
	}

	getBeyondSixPPAPPhase(siteCodeList, ppapOption, user,priorityType, navUserRole) : Observable<AllPPAPPhase> {
		console.log('Beyond Six Month Type Phase Service Call Start');
		const params = new HttpParams().set('ppapOption', ppapOption).set('user', user).set('priorityType',priorityType).set('navUserRole',navUserRole)
		return this.http.post<PswResponse>(this.httpUrlGetBeyondSixPPAPPhase, siteCodeList,{params})
			.pipe(map((pswResponse:PswResponse) => {
				return new AllPPAPPhase(pswResponse.mapOfStringLong)
			}));
		console.log('Beyond Six Month Phase Service Call End');
	}

	getPastDuePPAPPhase(siteCodeList, ppapOption, user, priorityType, navUserRole) : Observable<AllPPAPPhase> {
		console.log('Past Due Type Phase Service Call Start');
		const params = new HttpParams().set('ppapOption', ppapOption).set('user', user).set('priorityType',priorityType).set('navUserRole',navUserRole)
		return this.http.post<PswResponse>(this.httpUrlGetPastDuePPAPPhase, siteCodeList,{params})
			.pipe(map((pswResponse: PswResponse) => {
				console.log('PSW Response '+JSON.stringify(pswResponse.mapOfStringLong));
				console.log('PSW Response All PPAP PHASE'+JSON.stringify(new AllPPAPPhase(pswResponse.mapOfStringLong)));
				return new AllPPAPPhase(pswResponse.mapOfStringLong)
			}));
		console.log('Past Due Phase Service Call End');
	}

	getNextSixPPAPPhase(siteCodeList, ppapOption, user, priorityType, navUserRole) : Observable<AllPPAPPhase> {
		console.log('Next Six Month Type Phase Service Call Start');
			const params = new HttpParams().set('ppapOption', ppapOption).set('user', user).set('priorityType',priorityType).set('navUserRole',navUserRole)
		return this.http.post<PswResponse>(this.httpUrlGetNextSixPPAPPhase,siteCodeList,{params})
			.pipe(map((pswResponse: PswResponse) => {
				return new AllPPAPPhase(pswResponse.mapOfStringLong)
			}));
		console.log('Next Six Month Phase Service Call End');
	}

	getPartsUntimedPPAPPhase(siteCodeList, ppapOption, user,priorityType, navUserRole) : Observable<AllPPAPPhase> {
		console.log('Parts Untimed Type Phase Service Call Start');
		const params = new HttpParams().set('ppapOption', ppapOption).set('user', user).set('priorityType',priorityType).set('navUserRole',navUserRole)
		return this.http.post<PswResponse>(this.httpUrlGetPartsUntimedPPAPPhase,siteCodeList,{params})
			.pipe(map((pswResponse:PswResponse) => {
				return new AllPPAPPhase(pswResponse.mapOfStringLong);
			}));
		console.log('Parts Untimed Type Phase Service Call End');
	}

	getData(siteCodeList, phase, plan, ppapPhase, ppapOption, priorityType) {
		const userRole = sessionStorage.getItem('navUserRole').toUpperCase();
		const userId  = sessionStorage.getItem('userId');
		//const params = new HttpParams().set('siteCodeList', siteCodeList).set('phase',phase).set('plan',plan).set('ppapPhase',ppapPhase).set('ppapOption', ppapOption).set('navUserRole',userRole).set('userId',userId);
		const params = new HttpParams().set('phase',phase).set('plan',plan).set('ppapPhase',ppapPhase).set('ppapOption', ppapOption).set('priorityType',priorityType)
			.set('userRole',userRole).set('userId',userId);
		return this.http.post<any[]>(this.httpUrlGetData  ,siteCodeList, {params});
	}

	getSupplierName(siteCode: any) {
		console.log('Supplier Name Service Call Start');
		return this.http.get<any>(this.httpUrlGetSupplierName + '/' + siteCode);
		console.log('Supplier Name Service Call End');
	}

	getPpapElementSection(ppapPhase)  {
		console.log('Chart Element Sections '+ this.httpUrlPpapElementSection);
		return this.http.get<any[]>(this.httpUrlPpapElementSection + '/' + ppapPhase);
	}
	getPpapElementQuestion(ppapPhase)  {
		console.log('Chart Element Questions '+ this.httpUrlPpapElementQuestion);
		return this.http.get<any[]>(this.httpUrlPpapElementQuestion + '/' + ppapPhase);
	}

	saveSubSuppplierData(subSupplierPpapPackage: SubSupplierPpapPackage): Observable<any>  {
		//console.log('PPAP Package Save Call');
		return this.http.post<any>(this.httpUrlSubSupplierPpap, subSupplierPpapPackage);
	}

	// remove the Implementation from Service as well
	// getAllData() {
	// 	return this.http.get<any[]>(this.httpUrlSubSupplierLoadedData);
	// }
	//
	// deleteData(subSupplierPpapPackageId: number) {
	// 	return this.http.delete<any>(this.httpUrlSubSupplierDeleteRecord + '/' +subSupplierPpapPackageId,{responseType:'json'});
	// }
	//
	// updateData(products) {
	// 	return this.http.put<any>(this.httpUrlSubSupplierUpdateRecord+'/'+products.subSupplierPpapPackageID, products);
	// }

	saveData(ppapPackageIdentifierSave : PpapPackageIdentifier): Observable<any> {
		return this.http.post<any>(this.httpUrlPpapPackageSave, ppapPackageIdentifierSave,{responseType:'json'});
	}

	getDataOfSelectedRow(packageIdentifier: any): Observable<PswResponse> {
		return this.http.get<PswResponse>(this.httpUrlPpapPackageRetrieve+'/'+packageIdentifier);
	}

	saveCommodityImage(uploadCommodityImage: FormData){
		console.log('Savinig Commodity Image New Service');
		return this.http.post(this.httpUrlPpapPackageCommodityimageSave, uploadCommodityImage);
	}

	//Gets called when the user clicks on retieve image button to get the image from back end
	retrieveCommodityImage(identifierId: any) {
		return this.http.get(this.httpUrlPpapPackageRetrieveCommodityimage +identifierId);
	}
	//Alert message - US3738472(retrieve parts data which is not present in cmms)
	getAllData(packageIdentifier: any): Observable<any> {
		return this.http.get<any>(this.httpUrlPpapPackagePartsRetrieve+'/'+packageIdentifier);
	}

	getAllPartsWoCmmsApproval(packageIdentifier: any): Observable<any> {
		return this.http.get<any>(this.httpUrlPpapPartsCmmsApprovalStatus+'/'+packageIdentifier);
	}

	getNoActionRequiredPPAPPhase(siteCodeList, ppapOption, user, priorityType, navUserRole) : Observable<AllPPAPPhase> {
		console.log('No Action Required Phase Service Call Start');
		const params = new HttpParams().set('ppapOption', ppapOption).set('user', user).set('priorityType',priorityType).set('navUserRole',navUserRole)
		return this.http.post<PswResponse>(this.httpUrlGetNoActionRequiredPPAPPhase,siteCodeList,{params})
			.pipe(map((pswResponse:PswResponse) => {
				return new AllPPAPPhase(pswResponse.mapOfStringLong)
			}));
		console.log('No Action Required Type Phase Service Call End');
	}

	getProgramCodeValues() {
		return this.http.get<PswResponse>(this.httpUrlGetProgramCodeValues);
	}

	getProgramCodeSiteValuesFromParts() {
		return this.http.get<PswResponse>(this.httpUrlGetProgramSiteCodeValuesFromParts);
	}

	getPgmBeyondSixMonthValues(programCodesList, ppapOption, user) {
		console.log('Beyond 6 months Tree Map Values Service Call Start');
		const params = new HttpParams().set('programCodesList',programCodesList).set('ppapOption', ppapOption).set('user', user);
		return this.http.get<any>(this.httpUrlPgmBeyondSixMonthValues, {params});
		console.log('Beyond 6 months Tree Map Values Service Call End');
	}


	getPgmPastDueValues(programCodesList,ppapOption, user) {
		console.log('Past Due Tree Map Values Service Call Start');
		const params = new HttpParams().set('programCodesList',programCodesList).set('ppapOption', ppapOption).set('user', user);
		return this.http.get<any>(this.httpUrlPgmPastDueValues, {params});
		console.log('Past Due Tree Map Values Service Call End');
	}

	getPgmNextSixMonthValues(programCodesList, ppapOption, user) {
		console.log('Next Six Months Tree Map Values Service Call Start');
		const params = new HttpParams().set('programCodesList',programCodesList).set('ppapOption', ppapOption).set('user', user);
		return this.http.get<any>(this.httpUrlPgmNextSixMonthValues, {params});
		console.log('Past Due Tree Map Values Service Call End');
	}

	getPgmPartsUntimedValues(programCodesList,ppapOption, user) {
		console.log('VPP Untimed Tree Map Values Service Call Start');
		const params = new HttpParams().set('programCodesList',programCodesList).set('ppapOption', ppapOption).set('user', user);
		return this.http.get<any>(this.httpUrlPgmPartsUntimedValues, {params});
		console.log('VPP Untimed Tree Map Values Service Call End');
	}

	getPrgmBeyondSixPPAPPhase(programCodeList, ppapOption, user, priorityType) : Observable<AllPPAPPhase> {
		console.log('Beyond Six Month Type Phase Service Call Start');
		const params = new HttpParams().set('programCodeList',programCodeList).set('ppapOption', ppapOption).set('user', user).set('priorityType',priorityType)
		return this.http.get<PswResponse>(this.httpUrlGetPrgmBeyondSixPPAPPhase, {params})
			.pipe(map((pswResponse:PswResponse) => {
				return new AllPPAPPhase(pswResponse.mapOfStringLong)
			}));
		console.log('Beyond Six Month Phase Service Call End');
	}

	getPrgmPastDuePPAPPhase(programCodeList , ppapOption, user, priorityType) : Observable<AllPPAPPhase> {
		console.log('Past Due Type Phase Service Call Start');
		const params = new HttpParams().set('programCodeList',programCodeList)
			.set('ppapOption', ppapOption).set('user', user).set('priorityType',priorityType)
		return this.http.get<PswResponse>(this.httpUrlGetPrgmPastDuePPAPPhase,{params})
			.pipe(map((pswResponse:PswResponse) => {
				return new AllPPAPPhase(pswResponse.mapOfStringLong)
			}));
		console.log('Past Due Phase Service Call End');
	}

	getPrgmNextSixPPAPPhase(programCodeList, ppapOption, user, priorityType) : Observable<AllPPAPPhase> {
		console.log('Next Six Month Type Phase Service Call Start');
			const params = new HttpParams().set('programCodeList',programCodeList).set('ppapOption', ppapOption).set('user', user).set('priorityType',priorityType)
		return this.http.get<PswResponse>(this.httpUrlGetPrgmNextSixPPAPPhase,{params})
			.pipe(map((pswResponse:PswResponse) => {
				return new AllPPAPPhase(pswResponse.mapOfStringLong)
			}));
		console.log('Next Six Month Phase Service Call End');
	}

	getPrgmPartsUntimedPPAPPhase(programCodeList, ppapOption, user, priorityType) : Observable<AllPPAPPhase> {
		console.log('Parts Untimed Type Phase Service Call Start');
		const params = new HttpParams().set('programCodeList',programCodeList).set('ppapOption', ppapOption).set('user', user).set('priorityType',priorityType)
		return this.http.get<PswResponse>(this.httpUrlGetPrgmPartsUntimedPPAPPhase,{params})
			.pipe(map((pswResponse:PswResponse) => {
				return new AllPPAPPhase(pswResponse.mapOfStringLong)
			}));
		console.log('Parts Untimed Type Phase Service Call End');
	}

	getPrgmNoActionRequiredPPAPPhase(programCodeList, ppapOption, user,priorityType) : Observable<AllPPAPPhase> {
		console.log('No Action Required Phase Service Call Start');
		const params = new HttpParams().set('programCodeList',programCodeList).set('ppapOption', ppapOption).set('user', user).set('priorityType',priorityType)
		return this.http.get<PswResponse>(this.httpUrlGetPrgmNoActionRequiredPPAPPhase,{params})
			.pipe(map((pswResponse:PswResponse) => {
				return new AllPPAPPhase(pswResponse.mapOfStringLong)
			}));
		console.log('No Action Required Type Phase Service Call End');
	}

	getProgramData(programCodeList, phase, plan, ppapPhase, ppapOption, priorityType, userRole ) {
		const params = new HttpParams().set('programCodeList', programCodeList).set('phase',phase)
			.set('plan',plan).set('ppapPhase',ppapPhase).set('ppapOption', ppapOption).set('priorityType',priorityType).set('userRole',userRole);
		return this.http.get<any>(this.httpUrlGetProgramData  , {params});
	}

	getPrgmSupplierName(siteCode: any) {
		console.log('Supplier Name Service Call Start');
		return this.http.get<any>(this.httpUrlGetPrgmSupplierName + '/' + siteCode);
		console.log('Supplier Name Service Call End');
	}

	getProgramCodeSiteValues(siteList) {
		// const params = new HttpParams().set('siteList', siteList);
		return this.http.post<PswResponse>(this.httpUrlGetProgramSiteCodeValues,siteList);
	}



	getPgmSiteBeyondSixMonthValues(programCodesList, siteCodeList, user, navUserRole) {
		console.log('Beyond 6 months Tree Map Values Service Call Start');
		const params = new HttpParams().set('programCodesList',programCodesList).set('siteCodeList', siteCodeList).set('user', user).set('navUserRole',navUserRole);
		return this.http.get<any>(this.httpUrlPgmSiteBeyondSixMonthValues, {params});
		console.log('Beyond 6 months Tree Map Values Service Call End');
	}

	getPgmSitePastDueValues(programCodesList, siteCodeList, user, navUserRole) {
		console.log('Past Due Tree Map Values Service Call Start');
		const params = new HttpParams().set('programCodesList',programCodesList).set('siteCodeList', siteCodeList).set('user', user).set('navUserRole',navUserRole);
		return this.http.get<any>(this.httpUrlPgmSitePastDueValues, {params});
		console.log('Past Due Tree Map Values Service Call End');
	}

	getPgmSiteNextSixMonthValues(programCodesList, siteCodeList, user, navUserRole) {
		console.log('Next Six Months Tree Map Values Service Call Start');
		const params = new HttpParams().set('programCodesList',programCodesList).set('siteCodeList', siteCodeList).set('user', user).set('navUserRole',navUserRole);
		return this.http.get<any>(this.httpUrlPgmSiteNextSixMonthValues, {params});
		console.log('Past Due Tree Map Values Service Call End');
	}

	getPgmSitePartsUntimedValues(programCodesList, siteCodeList, user, navUserRole) {
		console.log('VPP Untimed Tree Map Values Service Call Start');
		const params = new HttpParams().set('programCodesList',programCodesList).set('siteCodeList', siteCodeList).set('user', user).set('navUserRole',navUserRole);
		return this.http.get<any>(this.httpUrlPgmSitePartsUntimedValues, {params});
		console.log('VPP Untimed Tree Map Values Service Call End');
	}

	getPrgmSiteBeyondSixPPAPPhase(programCodeList, siteCodeList, user, priorityType, navUserRole) : Observable<AllPPAPPhase> {
		console.log('Beyond Six Month Type Phase Service Call Start');
		const params = new HttpParams().set('programCodeList',programCodeList).set('user', user).set('priorityType',priorityType).set('navUserRole',navUserRole)
		return this.http.post<PswResponse>(this.httpUrlGetPrgmSiteBeyondSixPPAPPhase,siteCodeList, {params})
			.pipe(map((pswResponse:PswResponse) => {
				return new AllPPAPPhase(pswResponse.mapOfStringLong)
			}));
		console.log('Beyond Six Month Phase Service Call End');
	}

	getPrgmSitePastDuePPAPPhase(programCodeList, siteCodeList, user, priorityType, navUserRole) : Observable<AllPPAPPhase> {
		console.log('Past Due Type Phase Service Call Start');
		const params = new HttpParams().set('programCodeList',programCodeList).set('user', user).set('priorityType',priorityType).set('navUserRole',navUserRole)
		return this.http.post<PswResponse>(this.httpUrlGetPrgmSitePastDuePPAPPhase,siteCodeList,{params})
			.pipe(map((pswResponse:PswResponse) => {
				return new AllPPAPPhase(pswResponse.mapOfStringLong)
			}));
		console.log('Past Due Phase Service Call End');
	}

	getPrgmSiteNextSixPPAPPhase(programCodeList, siteCodeList, user, priorityType, navUserRole) : Observable<AllPPAPPhase> {
		console.log('Next Six Month Type Phase Service Call Start');
		const params = new HttpParams().set('programCodeList',programCodeList).set('user', user).set('priorityType',priorityType).set('navUserRole',navUserRole)
		return this.http.post<PswResponse>(this.httpUrlGetPrgmSiteNextSixPPAPPhase,siteCodeList,{params})
			.pipe(map((pswResponse:PswResponse) => {
				return new AllPPAPPhase(pswResponse.mapOfStringLong)
			}));
		console.log('Next Six Month Phase Service Call End');
	}

	getPrgmSitePartsUntimedPPAPPhase(programCodeList, siteCodeList, user, priorityType, navUserRole) : Observable<AllPPAPPhase> {
		console.log('Parts Untimed Type Phase Service Call Start');
		const params = new HttpParams().set('programCodeList',programCodeList).set('user', user).set('priorityType',priorityType).set('navUserRole',navUserRole)
		return this.http.post<PswResponse>(this.httpUrlGetPrgmSitePartsUntimedPPAPPhase,siteCodeList, {params})
			.pipe(map((pswResponse:PswResponse) => {
				return new AllPPAPPhase(pswResponse.mapOfStringLong)
			}));
		console.log('Parts Untimed Type Phase Service Call End');
	}

	getPrgmSiteNoActionRequiredPPAPPhase(programCodeList, siteCodeList, user, priorityType,navUserRole) : Observable<AllPPAPPhase> {
		console.log('No Action Required Phase Service Call Start');
		const params = new HttpParams().set('programCodeList',programCodeList).set('user', user).set('priorityType',priorityType).set('navUserRole',navUserRole)
		return this.http.post<PswResponse>(this.httpUrlGetPrgmSiteNoActionRequiredPPAPPhase,siteCodeList, {params})
			.pipe(map((pswResponse:PswResponse) => {
				return new AllPPAPPhase(pswResponse.mapOfStringLong)
			}));
		console.log('No Action Required Type Phase Service Call End');
	}

	getProgramSiteData(programCodeList, phase, plan, ppapPhase, siteCodeList, priorityType) {
		const params = new HttpParams().set('programCodeList', programCodeList).set('phase',phase)
			.set('plan',plan).set('ppapPhase',ppapPhase).set('siteCodeList', siteCodeList).set('priorityType',priorityType)
			.set('navUserRole',sessionStorage.getItem('navUserRole').toUpperCase()).set('user',sessionStorage.getItem('userId'));
		return this.http.get<any[]>(this.httpUrlGetProgramSiteData  , {params});
	}



	getExcelData(programNameOrSiteCode, identifyProgramOrSiteCode) {
		const params = new HttpParams().set('programNameOrSiteCode',programNameOrSiteCode).set('identifyProgramOrSiteCode',identifyProgramOrSiteCode);
		this.http.get(this.httpUrlGetExcelData, {params,responseType:'blob'})
			.subscribe((response)=> {
				this.downloadExcelFile(response,'text/csv')
			});
	}

	private downloadExcelFile(response: Blob, type: string) {
		const blob = new Blob([response], {type});
		const url = window.URL.createObjectURL(blob);
		const anchor = document.createElement('a');
		anchor.download = 'ePSW Report Download.xlsx';
		anchor.href = url;
		anchor.click();
	}
	getSiteCodesFromProgram(program: any,siteCodes: any) {
		console.log('344');
		return this.http.get<any>(this.httpUrlGetSiteCodeValuesForProgram + '/' + program + '/' + siteCodes);

	}

	getSiteCodesFromProgramCode(programCodes: any,siteCodes: any) {
		console.log('461');
		return this.http.get<any>(this.httpUrlGetSiteCodeValuesForProgramCode + '/' + programCodes + '/' + siteCodes);
	}

}
