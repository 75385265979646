import { Component, OnInit } from '@angular/core';
import {DropdownOption, programOption} from '../models/dropdown-option';
import {EpswHomepageService} from '../services/epsw-homepage.service';
import {AdhocReportService} from '../services/adhoc-report.service';
import {MessageService} from 'primeng/api';

@Component({
  selector: 'adhoc-report',
  templateUrl: './adhoc-report.component.html',
  styleUrls: ['./adhoc-report.component.scss']
})
export class AdhocReportComponent implements OnInit {
	programOption: DropdownOption[] = programOption;
	programData: any;
	programDetails: DropdownOption[] = [];
	selectedProgram: any;
	private dataValue: any;
	programType: any;
	siteEngineer: any;
	siteSupervisor: any;
	siteManager: any;
	staRole: string;
	staCdsids: string[];
	selectedBy= 'Program';
	style = { width: '250px' };// Or any width you desire
	disPlayingToasterMessage: boolean;
	fileName: string;



	constructor(private epswHomepageService: EpswHomepageService, public messageService: MessageService, private adhocReportService: AdhocReportService) {
	  this.programType=true;
	}

  ngOnInit(): void {

  }

	onChangingProgram(selectedProgram: any) {
		console.log('33');
		console.log(selectedProgram);
		console.log(this.programData);
		if (selectedProgram.name === 'By Program') {
			this.selectedBy= 'Program';
			this.siteEngineer = false;
			this.siteManager = false;
			this.siteSupervisor = false;
			this.programType = true;
			this.selectedProgram = '';
			this.epswHomepageService.getProgramCodeValues().subscribe(value => {
				console.log(value);
				this.dataValue = value.mapOfStringListMap['programList'];
				console.log(this.dataValue);
				let dummy : DropdownOption[] = [];
				for (const dataOne of this.dataValue) {
					if (this.dataValue !== undefined && this.dataValue.length !== 0) {
						console.log(dataOne);
						console.log(dataOne[1]);
						const con = dataOne['tepsw_batch_001_program_code']+'-'+dataOne['tepsw_batch_001_program_name'];
						dummy.push({
							name: con,
							code: con
						});

					}
				}
				console.log(this.programDetails);
				this.programDetails = dummy;
				console.log(this.programDetails);
			});
		}else if (selectedProgram.name === 'By Site Engineer') {
			this.selectedBy= 'Engineer CDSIDs';
			this.staRole = 'staEngineer';
			this.adhocReportService.getSTACDSIDs(this.staRole).subscribe(cdsids => {
				console.log(cdsids);
				let newOptionList: DropdownOption[] = [];
				for (const stacdsid of cdsids) {
					console.log(stacdsid)
					let newOption: DropdownOption = ({name: '', code: ''});
					newOption.code = stacdsid;
					newOption.name = stacdsid;
					newOptionList.push(newOption);
				}
				this.programDetails = newOptionList
				console.log(this.programDetails)
				this.siteEngineer = true;
				this.siteManager = false;
				this.siteSupervisor = false;
				this.programType = false;

			});
		} else if (selectedProgram.name === 'By Site Supervisor') {
			this.selectedBy= 'Supervisor CDSIDs';
			this.staRole = 'staSupervisor';
			this.adhocReportService.getSTACDSIDs(this.staRole).subscribe(cdsids => {
				console.log(cdsids);
				let newOptionList: DropdownOption[] = [];
				for (const stacdsid of cdsids) {
					console.log(stacdsid)
					let newOption: DropdownOption = ({name: '', code: ''});
					newOption.code = stacdsid;
					newOption.name = stacdsid;
					newOptionList.push(newOption);
				}
				this.programDetails = newOptionList
				console.log(this.programDetails)
				this.siteEngineer = false;
				this.siteManager = false;
				this.siteSupervisor = true;
				this.programType = false;
			});
		} else if (selectedProgram.name === 'By Site Manager') {
			this.selectedBy= 'Manager CDSIDs';
			this.staRole = 'staManager';
			this.adhocReportService.getSTACDSIDs(this.staRole).subscribe(cdsids => {
				console.log(cdsids);
				let newOptionList: DropdownOption[] = [];
				for (const stacdsid of cdsids) {
					console.log(stacdsid)
					let newOption: DropdownOption = ({name: '', code: ''});
					newOption.code = stacdsid;
					newOption.name = stacdsid;
					newOptionList.push(newOption);
				}
				this.programDetails = newOptionList
				console.log(this.programDetails)

				this.siteEngineer = false;
				this.siteManager = true;
				this.siteSupervisor = false;
				this.programType = false;
			});
		} else {
			this.selectedProgram
		}
	}


	getDetailsInExcel() {
		const date = new Date();
		const padStart = (value: number): string =>
			value.toString().padStart(2, '0');
		const dateFormat = `${padStart(date.getDate())}${padStart(date.getMonth() + 1)}${date.getFullYear()}`;

		if(this.programData ===undefined && this.selectedProgram ===undefined ||
		this.programData === null && this.selectedProgram === null ||
		this.selectedProgram === '') {
			this.disPlayingToasterMessage = true;
			this.messageService.add({
				severity: 'info',
				summary: 'Please select program',
				life: 8000
			});

		} else {
			if (this.siteEngineer === true) {
				this.fileName = 'PPAP Adhoc Report STAEngineer_'+this.selectedProgram.name+'_'+dateFormat;
				this.adhocReportService.generateExcelForSTASiteEngineer(this.selectedProgram.name, 'STA', this.fileName);
			}
			if (this.siteManager === true) {
				this.fileName = 'PPAP Adhoc Report STAManager_'+this.selectedProgram.name+'_'+dateFormat;
				this.adhocReportService.generateExcelForSTASiteEngineer(this.selectedProgram.name, 'staManager', this.fileName);
			}
			if (this.siteSupervisor === true) {
				this.fileName = 'PPAP Adhoc Report STASupervisor_'+this.selectedProgram.name+'_'+dateFormat;
				this.adhocReportService.generateExcelForSTASiteEngineer(this.selectedProgram.name, 'staSupervisor', this.fileName);
			}
			if (this.selectedBy === 'Program') {
				let split = this.selectedProgram.name.split('-');
				var splitElement = split[0];
				this.fileName = 'PPAP Adhoc Report Program_'+splitElement+'_'+dateFormat;
				//const dateFormat = `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()}`;
				this.adhocReportService.generateExcelForSTASiteEngineer(splitElement, 'Program', this.fileName);
				//this.adhocReportService.generateExcelForSTASiteEngineer(this.selectedProgram.name);
			}
		}

	}

	getCancel() {
     this.programData = '';
	 this.selectedProgram='';
	}
}
