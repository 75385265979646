import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AppComponent} from './app.component';
import {NavComponent} from './nav/nav.component';
import {ButtonModule} from 'primeng/button';
import {MenubarModule} from 'primeng/menubar';
import {PanelMenuModule} from 'primeng/panelmenu';
import {SidebarModule} from 'primeng/sidebar';
import {AppRoutingModule} from './app-routing.module';
import {ExamplePageComponent} from './example-page/example-page.component';
import {ToastModule} from 'primeng/toast';
import {NotFoundComponent} from './not-found/not-found.component';
import {ConfirmationService, MessageService} from 'primeng/api';
import {OauthModule} from './oauth/oauth.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserIncompatableComponent} from './browser-incompatable/browser-incompatable.component';
import {DropdownModule} from 'primeng/dropdown';
import {EpswHomepageComponent} from './epsw-homepage/epsw-homepage.component';
import {TableModule} from 'primeng/table';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {InputTrimModule} from 'ng2-trim-directive';
import {
	AccordionModule,
	AutoCompleteModule,
	CalendarModule,
	CardModule,
	ChartModule,
	CheckboxModule,
	ConfirmDialogModule,
	DialogModule,
	FileUploadModule,
	InputTextModule,
	ListboxModule,
	MessageModule,
	OverlayPanelModule,
	PickListModule,
	TooltipModule,
	RadioButtonModule,
	TabMenuModule,
	TabViewModule,
	ToggleButtonModule,
	ToolbarModule,
	BreadcrumbModule,
	ProgressSpinnerModule, FieldsetModule, InputNumberModule, InputSwitchModule
} from 'primeng';
// import {EpswFormComponent} from './epsw-form/epsw-form.component';
import {DigitalPPAPPackageComponent} from './digital-ppap-package/digital-ppap-package.component';
// import {DesignRecordsComponent} from './design-records/design-records.component';
import {AuthorizedEngineeringComponent} from './authorized-engineering/authorized-engineering.component';
// import { PartNumbersListComponent } from './part-numbers-list/part-numbers-list.component';
import {HttpRequestInterceptor} from './services/http-request-interceptor.service';
import {HttpErrorHandlerService} from './services/http-error-handler.service';
import {PpapPhaseSectionComponent} from './master/ppap-phase-section/ppap-phase-section.component';
import {PpapPhaseQuestionComponent} from './master/ppap-phase-question/ppap-phase-question.component';
import {SupplierViewComponent} from './supplier-view/supplier-view.component';
import {PpapElementsComponent} from './digital-ppap-package/ppap-elements/ppap-elements.component';
import {PpapPartsComponent} from './digital-ppap-package/ppap-parts/ppap-parts.component';
import {PswPartFormComponent} from './supplier-view/psw-part-form/psw-part-form.component';
import {AddRowDirective} from './digital-ppap-package/add-row.directive';
import {DatePipe} from '@angular/common';
import {NoAccessComponent} from './no-access/no-access.component';
import {PrintPswFormComponent} from './supplier-view/psw-part-form/print-psw-form/print-psw-form.component';
import {NumericDecimalDirective} from './directive/numeric-decimal/numeric-decimal.directive';
import {HelpComponent} from './help/help.component';
import { CreateBulletinsComponent } from './master/bulletin/create-bulletins/create-bulletins.component';
import {ManageBulletinsComponent} from './master/bulletin/manage-bulletins/manage-bulletins.component';
import {ShowBulletinsDialogComponent} from './master/bulletin/show-bulletins-dialog/show-bulletins-dialog.component';
import { AdhocReportComponent } from './adhoc-report/adhoc-report.component';


@NgModule({
	declarations: [
		AppComponent,
		NavComponent,
		ExamplePageComponent,
		NotFoundComponent,
		BrowserIncompatableComponent,
		EpswHomepageComponent,
		// EpswFormComponent,
		DigitalPPAPPackageComponent,
		// DesignRecordsComponent,
		AuthorizedEngineeringComponent,
		PpapPhaseQuestionComponent,
		PpapPhaseSectionComponent,
		// PartNumbersListComponent,
		SupplierViewComponent,
		PpapElementsComponent,
		PpapPartsComponent,
		PswPartFormComponent,
		AddRowDirective,
		NoAccessComponent,
		PrintPswFormComponent,
		NumericDecimalDirective,
		HelpComponent,
		AdhocReportComponent,
		CreateBulletinsComponent,
		ManageBulletinsComponent,
		ShowBulletinsDialogComponent,

	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		RouterModule,
		AppRoutingModule,
		ButtonModule,
		MenubarModule,
		PanelMenuModule,
		SidebarModule,
		ToastModule,
		OauthModule,
		HttpClientModule,
		DropdownModule,
		TableModule,
		ScrollPanelModule,
		FormsModule,
		CardModule,
		CheckboxModule,
		RadioButtonModule,
		AccordionModule,
		CalendarModule,
		InputTextModule,
		DialogModule,
		OverlayPanelModule,
		ChartModule,
		FileUploadModule,
		ReactiveFormsModule,
		MessageModule,
		ToggleButtonModule,
		AutoCompleteModule,
		ToolbarModule,
		ListboxModule,
		TabMenuModule,
		TabViewModule,
		ConfirmDialogModule,
		PickListModule,
		InputTrimModule,
		BreadcrumbModule,
		TooltipModule,
		ProgressSpinnerModule,
		FieldsetModule,
		InputNumberModule,
		InputSwitchModule
	],
	providers: [
		MessageService, DatePipe,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpRequestInterceptor,
			multi: true
		},
		HttpErrorHandlerService,
		ConfirmationService
	],
	bootstrap: [AppComponent]
})

export class AppModule {
}
