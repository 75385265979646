import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {BulletinsService} from '../../../services/bulletins.service';
import {Bulletin} from '../../../models/bulletins';
import {ConfirmationService, MessageService} from 'primeng/api';

@Component({
	selector: 'manage-bulletins',
	templateUrl: './manage-bulletins.component.html',
	styleUrls: ['./manage-bulletins.component.scss']
})
export class ManageBulletinsComponent implements OnInit {

	bulletins: Bulletin[];
	constructor(private router: Router,private bulletinService: BulletinsService,private messageService:MessageService,private confirmationService:ConfirmationService) { }
	ngOnInit(): void {
		this.getAllBulletin();
	}

	createBulletIn() {
		this.router.navigate(['/bulletin/create-bulletins/create/0']);
	}

	getAllBulletin() {
		this.bulletinService.getAllBulletin().subscribe(
			(resp) => {
				this.bulletins = resp;
				this.bulletins.forEach(bulletin => {
					bulletin.linksArray = bulletin.link.split(' ');
				});
			},
			(error) => {
				console.log('Error in retriving all bulletin ' + error);
				this.messageService.add({
					severity: 'error',
					summary: 'Error',
					detail:
						'Error in getting all bulletin\n' +
						JSON.stringify(error.message),
					life: 4000
				});
			}
		);
	}

	onEditBulletin(bulletinId: number) {
		console.log('edit bulletin');
		this.router.navigate(['/bulletin/create-bulletins/edit/' + bulletinId]);
	}

	onDeleteBulletin(id: number) {
		this.confirmationService.confirm({
			message:
				'Are you sure you want to delete Bulletin ?',
			header: 'Delete Confirmation',
			icon: 'pi pi-times-circle',
			accept: () => {this.bulletinService.deleteBulletin(id).subscribe((resp) => {
				this.messageService.add({
								severity: 'success',
								summary: 'Successful',
								detail: 'Bulletin has been deleted successfully',
								life: 2000
							});
							this.getAllBulletin();
							},
				(error) => {console.log('Error in deleting bulletin ' + error);
					this.messageService.add({
						severity: 'error',
						summary: 'Error',
						detail:
							'Error in deleting bulletin\n' +
							JSON.stringify(error.message),
						life: 4000
					});
				});
			}
		});
	}

	goToUrl(link: string) {
		window.open(link, '_blank');
	}
}
